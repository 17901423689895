import React from 'react'

import SEO from '../components/SEO/SEO'
import { Link } from 'gatsby'
import Transition from '../components/Transition/Transition'

const NotFoundPage: React.FC = () => {
    return (
        <Transition>
            <SEO title='404: Not found' />
            <div style={{ textAlign: 'center' }}>
                <h1>THE PAGE YOU ARE LOOKING FOR DOES NOT EXIST️</h1>
                <p style={{ fontSize: '3.75rem' }}>🤷‍♂</p>
                <p>
                    <span>Why don&apos;t you have a look at </span>
                    <Link to={'/projects'}>my projects</Link>
                    <span> instead?</span>
                </p>
            </div>
        </Transition>
    )
}

export default NotFoundPage
